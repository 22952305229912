@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&family=Roboto:wght@400;500&display=swap");

/* Fonts */
.text_raleway {
  font-family: "Raleway", sans-serif;
}

.text_roboto {
  font-family: "Roboto", sans-serif;
}

/* Home Page */
.hero_section .left {
  background-image: url("./Images/Circle/left.png");
}

.hero_section .bottom {
  background-image: url("./Images/Circle/bottom.png");
}

.hero_section .bg {
  background-image: url("./Images/Circle/bg.png");
}

.hero_section .swiper-wrapper {
  align-items: center;
}
